/* eslint-disable import/no-extraneous-dependencies */
import Analytics from 'analytics';
import googleTagManager from '@analytics/google-tag-manager';

const analytics = Analytics({
  app: 'tgrthaber',
  version: 100,
  plugins: [
    googleTagManager({
      containerId: 'GTM-KVCTQPP',
    }),
  ],
});

export default analytics;
