/* eslint-disable no-continue */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-bitwise */
/* eslint-disable camelcase */
/* eslint-disable no-param-reassign */
/* eslint-disable no-undef */
/* eslint-disable no-plusplus */
import $ from 'jquery';
import 'lazysizes';
import 'core-js/stable';
import analytics from './analytics';

// preload
import plugins from './widgets/plugins/plugins';

// widgets
import advert from './widgets/advert/index';
import autoplayVideo from './widgets/autoplay-video/index';
import breakingNews from './widgets/breaking-news/index';
import category from './widgets/category/index';
import common from './widgets/common/index';
import contentPool from './widgets/content-pool/index';
import covidMap from './widgets/map/index';
import cryptocurrencyMarkets from './widgets/cryptocurrency-markets/index';
import exchangeCalculator from './widgets/exchange-calculator/index';
import exchangeGraphic from './widgets/exchange-graphic/index';
import exchangeRate from './widgets/exchange-rate/index';
import exchangeSummary from './widgets/exchange-summary/index';
import exchangeTable from './widgets/exchange-table/index';
import fixtures from './widgets/fixtures/index';
// import footballFeild from './widgets/footballFeild/index';
import footer from './widgets/footer/index';
import forms from './widgets/forms/index';
import frekans from './widgets/frekans/index';
import goldCalculator from './widgets/gold-calculator/index';
import graphic from './widgets/graphic-with-duration/index';
import header from './widgets/header/index';
import headlineNews from './widgets/headline-news/index';
import liveLecture from './widgets/live-lecture/index';
import liveMatch from './widgets/live-match/index';
import liveMatchDetail from './widgets/live-match-detail/index';
import news from './widgets/news/index';
import newsDetail from './widgets/newsDetail/index';
import onPageLiveBroadcast from './widgets/onpage-livebroadcast/index';
import photoSpotlight from './widgets/photo-spotlight/index';
import prayerTimes from './widgets/prayer-times/index';
import primaryList from './widgets/primary-list/index';
import programs from './widgets/programs/index';
import searchResult from './widgets/searchResult/index';
import sidebarFixture from './widgets/sidebar-fixture/index';
import sidebarNews from './widgets/sidebar-news/index';
import sidebarStandings from './widgets/sidebar-standings/index';
import sportHeadContent from './widgets/sport-head-content/index';
import spotlight from './widgets/spotlight/index';
import standings from './widgets/standings/index';
import staticPages from './widgets/static-pages/index';
import streaming from './widgets/streaming/index';
import videoNews from './widgets/video-news/index';
import weatherForecast from './widgets/weather-forecast/index';
import weatherForecastDetail from './widgets/weather-forecast-detail/index';
import whatHappenedTl from './widgets/what-happened-tl/index';
import WhatIsCurrenciesValue from './widgets/what-is-currencies-value/index';
import WhatIsGoldsValue from './widgets/what-is-golds-value/index';
import writerDetail from './widgets/writer-detail/index';
import writers from './widgets/writers/index';

// Styles
import './stylesheets/theme.scss';
import secim from './widgets/secim/index';

const galleryIO = function () {
  let IO;

  function gaTrack(path, page_no) {
    if (page_no !== undefined) {
      if (path.indexOf('?') >= 0) {
        path = path.substring(0, path.indexOf('?'));
      }
      if (page_no > 1) {
        path = `${path}?p=${page_no}`;
      }
    }

    // ga('set', { page: path, title });
    // ga('send', 'pageview');

    return path;
  }

  const sendProductImpression = (pageNo, send) => {
    window.history.replaceState({pageNo}, '', gaTrack(window.location.pathname, pageNo));

    if (send) {
      analytics.page({
        title: document.title,
        path: gaTrack(window.location.pathname, pageNo),
        // url: gaTrack(document.location.href, pageNo),
      });
    }
  };

  const IOCallback = function (entries) {
    for (let i = 0; i < entries.length; i++) {
      const entry = entries[i];
      if (entry.intersectionRatio < 0.2 && !entry.isIntersecting) {
        continue;
      }

      // eslint-disable-next-line max-len
      sendProductImpression($(entry.target).data('page-no'), !$(entry.target).hasClass('init'));
      $(entry.target).addClass('init');
      // IO.unobserve(entry.target);
    }
  };

  const observeItems = function (item) {
    (item ? IO.observe(item) : null);
  };

  IO = new IntersectionObserver(IOCallback, {
    threshold: [0.2],
    rootMargin: '0px',
  });

  return {
    IOCallback,
    observeItems,
    sendProductImpression,
  };
};

$(window).on('load', () => {
  const initGalleryIO = galleryIO();
  $('.js-photo-gallery-item').each((index, element) => {
    initGalleryIO.observeItems(element);
  });
});

function getCookie(name) {
  const v = document.cookie.match(`(^|;) ?${name}=([^;]*)(;|$)`);
  return v ? v[2] : null;
}

function setCookie(name, value, days) {
  const d = new Date();
  d.setTime(d.getTime() + 24 * 60 * 60 * 1000 * days);
  document.cookie = `${name}=${value};path=/;expires=${d.toGMTString()}`;
}

const getSessionId = () => {
  if (!getCookie('user_session_id')) {
    const d = [...Array(10)].map(() => (~~(Math.random() * 36)).toString(36)).join('') + new Date().getUTCMilliseconds();
    setCookie('user_session_id', d, 1);
    return d;
  }

  return getCookie('user_session_id');
};

(function () {
  analytics.identify(getSessionId(), {
    firstName: getSessionId(),
    session: getSessionId(),
  });
  analytics.page();
  const widgets = [];

  // preloading
  plugins();
  $('.preloading').hide();

  // Yeni widget oluşturulduğunda `widgets` array içerisine eklenmesi gerekecek.
  widgets.push(advert);
  widgets.push(autoplayVideo);
  widgets.push(breakingNews);
  widgets.push(category);
  widgets.push(contentPool);
  widgets.push(common);
  widgets.push(covidMap);
  widgets.push(cryptocurrencyMarkets);
  widgets.push(exchangeCalculator);
  widgets.push(exchangeGraphic);
  widgets.push(exchangeRate);
  widgets.push(exchangeSummary);
  widgets.push(exchangeTable);
  widgets.push(fixtures);
  // widgets.push(footballFeild);
  widgets.push(frekans);
  widgets.push(footer);
  widgets.push(forms);
  widgets.push(goldCalculator);
  widgets.push(graphic);
  widgets.push(header);
  widgets.push(headlineNews);
  widgets.push(liveLecture);
  widgets.push(liveMatch);
  widgets.push(liveMatchDetail);
  widgets.push(newsDetail);
  widgets.push(news);
  widgets.push(onPageLiveBroadcast);
  widgets.push(photoSpotlight);
  // widgets.push(plugins);
  widgets.push(prayerTimes);
  widgets.push(primaryList);
  widgets.push(programs);
  widgets.push(searchResult);
  widgets.push(secim);
  widgets.push(sidebarFixture);
  widgets.push(sidebarNews);
  widgets.push(sidebarStandings);
  widgets.push(sportHeadContent);
  widgets.push(spotlight);
  widgets.push(standings);
  widgets.push(staticPages);
  widgets.push(streaming);
  widgets.push(videoNews);
  widgets.push(weatherForecast);
  widgets.push(weatherForecastDetail);
  widgets.push(whatHappenedTl);
  widgets.push(WhatIsCurrenciesValue);
  widgets.push(WhatIsGoldsValue);
  widgets.push(writerDetail);
  widgets.push(writers);

  // IntersectionObserver function
  let IO;
  const IOCallback = function (entries) {
    for (let i = 0; i < entries.length; i++) {
      const entry = entries[i];
      if (entry.intersectionRatio < 0.2 && !entry.isIntersecting) {
        continue;
      }
      const className = entry.target.getAttribute('class') || '';
      if (entry.target.getAttribute('data-index') && className?.indexOf('lazy-component') === -1) {
        const idx = entry.target.getAttribute('data-index').split(',');
        const inits = [];
        for (let index = 0; index < idx.length; index++) {
          const element = widgets[idx[index]];
          inits.push(element);
        }
        for (let index = 0; index < inits.length; index++) {
          const element = inits[index];
          element?.init();
        }
      }

      entry.target.classList.add('lazy-component');
      IO.unobserve(entry.target);
    }
  };

  const observe = function (item) {
    (item ? IO.observe(item) : null);
  };

  IO = new IntersectionObserver(IOCallback, {
    threshold: [0],
    rootMargin: '0px',
  });
  // register widget index
  for (let i = 0; i < widgets.length; i++) {
    const element = widgets[i];
    const targets = document.querySelectorAll(element.selector);
    if (targets.length > 0) {
      for (let index = 0; index < targets.length; index++) {
        const target = targets[index];
        if (target.getAttribute('data-index')) {
          const ar = target.getAttribute('data-index').split(',');
          ar.push(i);
          target.setAttribute('data-index', ar.join(','));
        } else {
          target.setAttribute('data-index', i);
        }
      }
    }
  }

  // init observe items
  for (let i = 0; i < widgets.length; i++) {
    const element = widgets[i];
    const targets = document.querySelectorAll(element.selector);
    if (targets.length > 0) {
      for (let index = 0; index < targets.length; index++) {
        const target = targets[index];
        observe(target);
      }
    }
  }

  document.addEventListener('lazybeforeunveil', (e) => {
    const bg = e.target.getAttribute('data-bg');
    if (bg) {
      e.target.style.backgroundImage = `url(${bg})`;
    }
  });
}());

window.fixMapHeight = (height) => {
  $('.svg-iframe').css('height', `${height + 20}px`);
};
