/* eslint-disable max-len */
const handle = async (module) => { const response = await module.default(); return response; };

export const advert = () => import(/* webpackChunkName: "advert" */ './advert/advert').then(handle);
export const autoplayVideo = () => import(/* webpackChunkName: "autoplayVideo" */ './autoplay-video/autoplayVideo').then(handle);
export const breakingNews = () => import(/* webpackChunkName: "breakingNews" */ './breaking-news/breaking-news').then(handle);
export const productDetail = () => import(/* webpackChunkName: "productDetail" */ './category/category').then(handle);
export const common = () => import(/* webpackChunkName: "common" */ './common/common').then(handle);
export const contentPool = () => import(/* webpackChunkName: "contentPool" */ './content-pool/content-pool').then(handle);
export const cryptocurrencyMarkets = () => import(/* webpackChunkName: "cryptocurrencyMarkets" */ './cryptocurrency-markets/cryptocurrency-markets').then(handle);
export const exchangeCalculator = () => import(/* webpackChunkName: "exchangeCalculator" */ './exchange-calculator/exchange-calculator').then(handle);
export const exchangeGraphic = () => import(/* webpackChunkName: "exchangeGraphic" */ './exchange-graphic/exchange-graphic').then(handle);
export const exchangeRate = () => import(/* webpackChunkName: "exchangeRate" */ './exchange-rate/exchange-rate').then(handle);
export const exchangeSummary = () => import(/* webpackChunkName: "exchangeSummary" */ './exchange-summary/exchange-summary').then(handle);
export const exchangeTable = () => import(/* webpackChunkName: "exchangeTable" */ './exchange-table/exchange-table').then(handle);
export const fixtures = () => import(/* webpackChunkName: "fixtures" */ './fixtures/fixtures').then(handle);
// export const footballFeild = () => import(/* webpackChunkName: "footballFeild" */ './footballFeild/footballFeild').then(handle);
export const footer = () => import(/* webpackChunkName: "footer" */ './footer/footer').then(handle);
export const forms = () => import(/* webpackChunkName: "forms" */ './forms/forms').then(handle);
export const frekans = () => import(/* webpackChunkName: "frekans" */ './frekans/frekans').then(handle);
export const goldCalculator = () => import(/* webpackChunkName: "goldCalculator" */ './gold-calculator/gold-calculator').then(handle);
export const graphic = () => import(/* webpackChunkName: "graphic" */ './graphic-with-duration/graphic').then(handle);
export const header = () => import(/* webpackChunkName: "header" */ './header/header').then(handle);
export const headlineNews = () => import(/* webpackChunkName: "headlineNews" */ './headline-news/headline-news.js').then(handle);
export const liveLecture = () => import(/* webpackChunkName: "liveLecture" */ './live-lecture/live-lecture').then(handle);
export const liveMatch = () => import(/* webpackChunkName: "liveMatch" */ './live-match/live-match').then(handle);
export const liveMatchDetail = () => import(/* webpackChunkName: "liveMatchDetail" */ './live-match-detail/live-match-detail').then(handle);
export const covid = () => import(/* webpackChunkName: "covid" */ './map/covid').then(handle);
export const news = () => import(/* webpackChunkName: "news" */ './news/news').then(handle);
export const newsDetail = () => import(/* webpackChunkName: "newsDetail" */ './newsDetail/newsDetail').then(handle);
export const onPageLiveBroadcast = () => import(/* webpackChunkName: "onPageLiveBroadcast" */ './onpage-livebroadcast/onpage-livebroadcast').then(handle);
export const photoSpotlight = () => import(/* webpackChunkName: "photoSpotlight" */ './photo-spotlight/photo-spotlight').then(handle);
export const plugins = () => import(/* webpackChunkName: "plugins" */ './plugins/plugins').then(handle);
export const prayerTimes = () => import(/* webpackChunkName: "prayerTimes" */ './prayer-times/prayer-times').then(handle);
export const primaryList = () => import(/* webpackChunkName: "primaryList" */ './primary-list/primary-list').then(handle);
export const programs = () => import(/* webpackChunkName: "programs" */ './programs/programs').then(handle);
export const searchResult = () => import(/* webpackChunkName: "searchResult" */ './searchResult/searchResult').then(handle);
export const sidebarNews = () => import(/* webpackChunkName: "sidebarNews" */ './sidebar-news/sidebar-news').then(handle);
export const sidebarFixture = () => import(/* webpackChunkName: "sidebarFixture" */ './sidebar-fixture/fixtur').then(handle);
export const sidebarStandings = () => import(/* webpackChunkName: "sidebarStandings" */ './sidebar-standings/sidebar-standings').then(handle);
export const headContent = () => import(/* webpackChunkName: "headContent" */ './sport-head-content/head-content.js').then(handle);
export const spotlight = () => import(/* webpackChunkName: "spotlight" */ './spotlight/spotlight.js').then(handle);
export const standings = () => import(/* webpackChunkName: "standings" */ './standings/standings.js').then(handle);
export const staticPage = () => import(/* webpackChunkName: "staticPage" */ './static-pages/static-page').then(handle);
export const streaming = () => import(/* webpackChunkName: "streaming" */ './streaming/streaming').then(handle);
export const videoNews = () => import(/* webpackChunkName: "videoNews" */ './video-news/video-news').then(handle);
export const weatherForecast = () => import(/* webpackChunkName: "weatherForecast" */ './weather-forecast/weather-forecast').then(handle);
export const weatherForecastDetail = () => import(/* webpackChunkName: "weatherForecastDetail" */ './weather-forecast-detail/weather-forecast-detail').then(handle);
export const whatHappenedTl = () => import(/* webpackChunkName: "whatHappenedTl" */ './what-happened-tl/what-happened-tl').then(handle);
export const whatIsCurrenciesValue = () => import(/* webpackChunkName: "whatIsCurrenciesValue" */ './what-is-currencies-value/what-is-currencies-value').then(handle);
export const whatIsGoldsValue = () => import(/* webpackChunkName: "whatIsGoldsValue" */ './what-is-golds-value/what-is-golds-value').then(handle);
export const writerDetail = () => import(/* webpackChunkName: "writerDetail" */ './writer-detail/writer-detail').then(handle);
export const writers = () => import(/* webpackChunkName: "writers" */ './writers/writers').then(handle);
export const vote = () => import(/* webpackChunkName: "vote" */ './secim/secim').then(handle);
